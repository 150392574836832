import React, { useState } from "react";

import {
    getCategories,
    getSubcategoriesForCategory,
    getTags
} from "../data/categories/categoriesProvider.js";
import {ASelect} from "../common/baseInputs.js";
import {SectionHeader} from "../common/layout.js";

const CategorySelect = (props) => {

    const [categories, setCategories] = useState(getCategories());
    const [selectedCategory, setSelectedCategory] = props.categoryStore;
    const [subcategories, setSubcategories] = useState(getSubcategoriesForCategory(''));
    const [selectedSubcategory, setSelectedSubcategory] = props.subcategoryStore;
    const [tags, setTags] = useState(getTags());
    const [selectedTag, setSelectedTag] = props.tagStore;

    const handleCategoryChange = (categoryCodeReceived) => {
        let newCategoryCode = categoryCodeReceived ? categoryCodeReceived : '';
        setSelectedCategory(newCategoryCode);
        setSubcategories(getSubcategoriesForCategory(newCategoryCode));
        setSelectedSubcategory('');
    }

    const mapCategoriesForPicker = (categories) => {
        return categories.map(
            (category) => {
                return {label: category.display, value: category.code};
            });
    }

    return (
        <>
            <SectionHeader>{props.name}</SectionHeader>
            <div style={{'width': '100%', 'display': 'flex'}}>
                <ASelect data={mapCategoriesForPicker(categories)}
                         count={2}
                         value={selectedCategory}
                         onChange={handleCategoryChange}
                         placeholder={'Choose one...'}
                         searchable={false}/>
                <ASelect data={mapCategoriesForPicker(subcategories)}
                         count={2}
                         readOnly={!selectedCategory || selectedCategory === ''}
                         value={selectedSubcategory}
                         onChange={setSelectedSubcategory}
                         placeholder={'Sub-category'}
                         searchable={false}/>
            </div>
            <ASelect data={mapCategoriesForPicker(tags)}
                     count={1}
                     value={selectedTag}
                     onChange={setSelectedTag}
                     placeholder={"Special Tag"}
                     searchable={false}/>
        </>
    )
}

export default CategorySelect;