import React from "react";
import {VerticalScrollBox} from "../common/layout.js";
import {Timeline} from "rsuite";
import {DateText, DetailText, MemoText} from "../common/general.js";

const TransactionTimeline = ({transactionList, shouldDisplay}) => {
    return (
        <VerticalScrollBox>
            <Timeline style={{'marginTop': '20px'}} isItemActive={(a, b) => false}>
                {transactionList.map((transaction, index) => {
                    if (shouldDisplay(transaction)) {
                        let transactionColor = transaction.isIncome ? 'green' : '';
                        let transactionAmount =
                            parseFloat(transaction.amount).toFixed(2).toString()
                                .concat(transaction.isReimbursable ? "(R)" : "")
                        let details = <>
                            <DateText>{transaction.date.substring(5)}</DateText>
                            <DetailText style={{'color':transactionColor}}>${transactionAmount}</DetailText>
                        </>

                        return (<Timeline.Item time={details} key={index}>
                            <MemoText>{transaction.memo}</MemoText>
                        </Timeline.Item>)
                    } else return null;
                })}
            </Timeline>
        </VerticalScrollBox>
    )
}

export default TransactionTimeline;