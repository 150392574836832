import React from "react";

import {SectionHeader} from "../common/layout.js";
import {ADatePicker} from "../common/baseInputs.js";

const DateInput = ({name, dateStore}) => {

    const [date, setDate] = dateStore;

    return (
        <>
            <SectionHeader>{name}</SectionHeader>
            <ADatePicker placeholder="Transaction Date"
                         value={date}
                         onChange={setDate}
                         onClean={() => setDate(new Date())}/>
        </>
    )
}

export default DateInput;