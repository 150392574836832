import React from "react";

import {HorizontalSpread, SectionHeader} from "../common/layout.js";
import {AToggle, InputNumber} from "../common/baseInputs.js";

const AmountInput = ({name, amountStore, isIncomeStore, isReimbursableStore}) => {

    const [amount, setAmount] = amountStore;
    const [isIncome, setIsIncome] = isIncomeStore;
    const [isReimbursable, setIsReimbursable] = isReimbursableStore;

    return (
        <>
            <SectionHeader>{name}</SectionHeader>
            <InputNumber value={amount} onChange={setAmount}/>
            <HorizontalSpread style={{'justifyContent':'space-between'}}>
                <AToggle checked={isIncome}
                         onChange={setIsIncome}
                         style={{'width':'30%'}}
                         checkedChildren="Income"
                         unCheckedChildren="Expense"/>
                <AToggle checked={isReimbursable}
                         onChange={setIsReimbursable}
                         style={{'width':'70%', 'float':'right'}}
                         checkedChildren="Refundable"
                         unCheckedChildren="Non-Refundable"/>
            </HorizontalSpread>
        </>
    )
}

export default AmountInput;