import React from "react";
import styled from "styled-components";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import 'rsuite/dist/rsuite.min.css';
import './components/css/custom.css';

import { Redirect } from "./components/common/utils.js";
import NewTransaction from "./components/pages/NewTransaction.js";
import Portal from "./components/pages/Portal.js";
import AdminDashboard from "./components/pages/AdminDashboard.js";

const MainApp = styled.div`
    font-family: monospace;
`;

const App = () => {
    return (
        <MainApp>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={ <Portal/> }/>
                    <Route path="/submit" element={ <NewTransaction/> }/>
                    <Route path="/admin" element={ <AdminDashboard/> }/>
                    <Route path="*" element={ <Redirect path="/"/> } />
                </Routes>
            </BrowserRouter>
        </MainApp>
    )
}

const root = createRoot(document.getElementById("mainDiv"));
root.render(<App />)