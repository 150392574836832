import React from "react";
import styled from "styled-components";

export const BaseColor = '#031d45';

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: clip;
  background-color: ${BaseColor};
`;

export const VertBox = styled.div`
  height: 80%;
  width: 80%;
  max-width: 20em;
  border-radius: 25px;
  background-color: antiquewhite;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AdminVertBox = styled.div`
  height: 80%;
  width: 80%;
  max-width: 22em;
  border-radius: 25px;
  background-color: antiquewhite;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Header = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  font-style: italic;
  font-variant: small-caps;
  text-align: left;
  width: 100%;
  padding-left: 0.2em
`;

export const BigHeader = styled.div`
  font-size: 3em;
  font-weight: bold;
  font-style: italic;
  font-variant: small-caps;
  text-align: center;
  margin-right: 0.2em;
`;

export const BigSectionHeader = styled.div`
  font-size: 1.2em;
  font-style: italic;
  text-align: center;
  margin-right: 0.2em;
`;

export const SectionHeader = styled.div`
  font-size: 1em;
  font-style: italic;
  text-align: left;
  width: 100%;
  padding-left: 0.2em;
  margin-top: 10px;
  margin-bottom: 2px;
`;

export const CaptionText = styled.div`
  font-size: 0.9em;
  font-style: italic;
  text-align: left;
  padding-left: 0.2em;
  margin-top: 5px;
  width: 100%
`;

export const VerticalScrollBox = styled.div`
  overflow: auto;
`;

export const StatusText = ({color, condition, children}) => {
    return <>
        {condition && <CaptionText style={{"color": color}}>{children}</CaptionText>}
    </>
}

export const BigStatusText = ({color, condition = true, children}) => {
    return <>
        {condition && <BigSectionHeader style={{"color": color}}>{children}</BigSectionHeader>}
    </>
}

export const HorizontalSpread = ({children, style}) => {
    let styleObject = {'width':'100%', 'display':'flex', ...style};
    return <div style={styleObject}>
        {children}
    </div>
}
