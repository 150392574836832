import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";

import {authenticate} from "../common/utils.js";
import TransactionList from "../admin/TransactionList.js";
import {AdminVertBox, CenterContainer, VertBox} from "../common/layout.js";

const AdminDashboard = () => {

    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        authenticate(res => {
            if (res.status !== 200) {
                navigate('/');
            } else {
                setAuthenticated(true);
            }
        });
    }, []);

    return(
        <>
            <CenterContainer>
                <AdminVertBox>
                    {authenticated && <>
                        <TransactionList/>
                    </>}
                </AdminVertBox>
            </CenterContainer>
        </>
    )
}

export default AdminDashboard;