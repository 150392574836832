import categories from "./categories.json";
import subcategories from "./subcategories.json";
import tags from "./tags.json";

export const getCategories = () => {
    return categories;
}

export const getSubcategories = () => {
    return subcategories;
}

export const getSubcategoriesForCategory = (categoryCode) => {
    return subcategories[categoryCode];
}

export const subcategoryCountForCategory = (categoryCode) => {
    return subcategories[categoryCode].length;
}

export const getTags = () => {
    return tags;
}