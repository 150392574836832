import React from "react";

import {Button, DatePicker, Input, InputGroup, SelectPicker, Toggle} from 'rsuite';

export const ASelect = (props) => {
    let percent = `${100 / props.count}%`
    return <SelectPicker {...props}
                         onClean={() => { return props.onChange('')}}
                         size={'xs'}
                         style={{'marginBottom':'2px', 'width':percent}}
                         appearance={'default'}/>
}

export const InputNumber = (props) => {

    return <InputGroup>
        <InputGroup.Addon>$</InputGroup.Addon>
        <Input {...props}
               appearance={'subtle'}
               inputMode='decimal'
               min={0}
               placeholder='0.00'
               type='number'
               size={'sm'}
               style={{'width':'100%'}}/>
    </InputGroup>
}

export const InputText = (props) => {
    return <Input {...props}/>
}

export const InputTextArea = (props) => {
    return <Input {...props}
                  as='textarea'
                  rows={3}
                  type='text'/>
}

export const AToggle = (props) => {
    return <Toggle {...props}
                   size={'md'}
                   style={{'marginTop':'5px'}}/>
}

export const ADatePicker = (props) => {
    return <DatePicker {...props}
                       format='MM-dd-yyyy'
                       oneTap
                       size={'xs'}
                       style={{'width':'100%'}}/>
}

export const MonthSelect = (props) => {
    return <DatePicker {...props}
                       format={'MM/yyyy'}
                       oneTap
                       size='xs'
                       style={{'width':'100%'}}/>
}

export const AButton = (props) => {
    return <Button {...props}
                   size={'xs'}
                   style={{'marginTop':'5px', 'width': props.width}}/>
}