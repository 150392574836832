// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-timeline-item-time {
    flex: 2 !important
}

.rs-timeline-item-content {
    flex: 4 !important;
}

.rs-timeline-item-dot {
    left: calc(33% + 2px) !important;
}

.rs-timeline-item-tail {
    left: calc(33% + 6px) !important;
}`, "",{"version":3,"sources":["webpack://./components/css/custom.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".rs-timeline-item-time {\n    flex: 2 !important\n}\n\n.rs-timeline-item-content {\n    flex: 4 !important;\n}\n\n.rs-timeline-item-dot {\n    left: calc(33% + 2px) !important;\n}\n\n.rs-timeline-item-tail {\n    left: calc(33% + 6px) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
