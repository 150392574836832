import React, {useEffect, useState} from "react";
import {Header, HorizontalSpread, StatusText} from '../common/layout.js';
import {MonthSelect} from "../common/baseInputs.js";
import {getSpentColor, standardizeDate, transactionComparator} from "../common/utils.js";
import CategorySelect from "../input/CategorySelect.js";
import {Button, ButtonGroup} from "rsuite";
import TransactionTimeline from "./TransactionTimeline.js";

const TransactionList = () => {

    const [date, setDate] = useState(new Date());
    const [transactionList, setTransactionList] = useState([]);
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [tag, setTag] = useState('');
    const [transactionTotal, setTransactionTotal] = useState(0);
    const [incomeTotal, setIncomeTotal] = useState(0);
    const [activeSort, setActiveSort] = useState('');

    const onCalendarChange = (newDate) => {
        setDate(newDate);
    }

    useEffect(() => {
        const fetchTransactions = async () => {
            const [year, month, day] = standardizeDate(date);
            const res = await fetch(`/transactions?year=${year}&month=${month}`);
            const jsonRes = await res.json();
            setTransactionList(jsonRes.reverse());
        }

        fetchTransactions();
    }, [date]);

    useEffect(() => {
        let sum = 0.0;
        let incomeSum = 0.0;
        transactionList.forEach((transaction) => {
            if (shouldConsiderRecord(transaction)) {
                let transactionValue = parseFloat(transaction.amount);
                if (transaction.isIncome) {
                    transactionValue *= -1;
                }
                sum += transactionValue;
            } else if (transaction.category === 'bread') {
                incomeSum += parseFloat(transaction.amount);
            }
        })
        setTransactionTotal(sum);
        setIncomeTotal(incomeSum);
    }, [transactionList, category, subcategory, tag])

    const sortTransactions = (type) => {
        setActiveSort(type);
        let sortedTransactionList = [...transactionList];
        sortedTransactionList.sort(transactionComparator(type));
        if (type === "date") {
            sortedTransactionList.reverse();
        }
        setTransactionList(sortedTransactionList);
    }

    const transactionMatchesCategory = (transaction) => {
        return ((category === '')
            || (category === transaction.category && subcategory === '')
            || (category === transaction.category && subcategory === transaction.subcategory));
    }

    const transactionMatchesTag = (transaction) => {
        return ((tag === '') || (tag === transaction.tag));
    }

    const globalExcluded = (transaction) => {
        return category !== 'bread' && transaction.category === 'bread';
    }

    const shouldConsiderRecord = (transaction) => {
        return transactionMatchesCategory(transaction)
            && transactionMatchesTag(transaction)
            && !globalExcluded(transaction);
    }

    return (
        <>
            <Header>Transactions</Header>
            <MonthSelect limitStartYear={(new Date()).getFullYear() - 2022}
                         limitEndYear={1}
                         value={date}
                         onChange={onCalendarChange}
                         onClean={() => onCalendarChange(new Date())}/>
            <CategorySelect name="Category"
                            categoryStore={[category, setCategory]}
                            subcategoryStore={[subcategory, setSubcategory]}
                            tagStore={[tag, setTag]}/>

            <ButtonGroup size={'xs'}>
                <Button onClick={() => sortTransactions("amount")}
                        active={activeSort === "amount"}>Amount</Button>
                <Button onClick={() => sortTransactions("date")}
                        active={activeSort === "date"}>Date</Button>
            </ButtonGroup>

            <HorizontalSpread>
            <StatusText condition={true} color={getSpentColor(transactionTotal)}>
                Spent: ${transactionTotal.toFixed(2).toString()}
            </StatusText>
            <StatusText condition={true} color={'green'}>
                Income: ${incomeTotal.toFixed(2).toString()}
            </StatusText>
            </HorizontalSpread>

            <TransactionTimeline transactionList={transactionList}
                                 shouldDisplay={shouldConsiderRecord}/>
        </>
    )
}

export default TransactionList;