import React, {useEffect, useState} from "react";

import {BaseColor, Header, StatusText} from "../common/layout.js"
import {AButton} from "../common/baseInputs.js";
import {useNavigate} from "react-router";
import {getSpentColor} from "../common/utils.js";

const NewTransactionConfirmation = ({restart, monthTotals}) => {

    const [spentColor, setSpentColor] = useState(BaseColor);
    const [[expenseTotal, incomeTotal], setTotals] = useState(monthTotals);
    const navigate = useNavigate();

    useEffect(() => {
        setTotals(monthTotals);
        let [totalSpent, totalEarned] = monthTotals;
        let color = getSpentColor(totalSpent);
        setSpentColor(color);
    }, [monthTotals]);

    return (
        <>
            <Header>Thank You!</Header>
            <StatusText condition={true}>
                This month, you have spent:
            </StatusText>
            <StatusText style={{'fontWeight':'bold'}}
                        color={spentColor}
                        condition={expenseTotal >= 0}>
                ${expenseTotal}
            </StatusText>
            <StatusText condition={true}>
                and earned:
            </StatusText>
            <StatusText style={{'fontWeight':'bold'}}
                        color={'green'}
                        condition={incomeTotal >= 0}>
                ${incomeTotal}
            </StatusText>
            <AButton width='100%' onClick={restart}>Submit Another</AButton>
            <AButton width='100%' onClick={() => navigate('/admin')}>Admin</AButton>
        </>
    )
}

export default NewTransactionConfirmation;