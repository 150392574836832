import React, {useState} from "react"

import {CenterContainer, VertBox} from "../common/layout.js";
import NewTransactionForm from "../input/NewTransactionForm.js";
import NewTransactionConfirmation from "../input/NewTransactionConfirmation.js";

const NewTransaction = () => {

    const [submissionComplete, setSubmissionComplete] = useState(false);
    const [totalSpent, setTotalSpent] = useState([]);

    const showConfirmationPage = () => setSubmissionComplete(true);
    const startNewSubmission = () => setSubmissionComplete(false);

    return <>
        <CenterContainer>
            <VertBox>
                {submissionComplete ?
                    <NewTransactionConfirmation restart={startNewSubmission}
                                                monthTotals={totalSpent}/> :
                    <NewTransactionForm showConfirmation={showConfirmationPage}
                                        setSaveTotal={setTotalSpent} />}
            </VertBox>
        </CenterContainer>
    </>
}

export default NewTransaction;