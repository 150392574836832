import React from "react";
import styled from "styled-components";
import {string} from "prop-types";
import {BaseColor} from "./layout.js";

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: clip;
  background-color: #031d45;
`;

export const VertBox = styled.div`
  height: 80%;
  width: 80%;
  max-width: 20em;
  border-radius: 25px;
  background-color: antiquewhite;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleText = styled.div`
  font-size: 3em;
  font-weight: bold;
  font-style: italic;
  font-variant: small-caps;
  text-align: center;
  margin-right: 0.2em;
`;

export const HeaderText = styled.div`
  font-size: 2em;
  font-weight: bold;
  font-style: italic;
  font-variant: small-caps;
  text-align: center;
  margin-right: 0.2em;
`;

export const SubtitleText = styled.div`
  font-size: 1.2em;
  font-style: italic;
  text-align: center;
  margin-right: 0.2em;
`;

export const CaptionText = styled.div`
  font-size: 1em;
  font-style: italic;
  text-align: center;
  margin-right: 0.2em;
  padding-top: 0.15em;
`;

export const DateText = styled.div`
  font-size: 1em;
  text-align: right;
`;

export const DetailText = styled.div`
  font-size: 1em;
  font-style: italic;
  font-weight: bold;
  text-align: right;
  padding-bottom: 18px;
`;

export const MemoText = styled.div`
  font-size: 1em;
  text-align: left;
`;

export const AnyInput = styled.input`
  width: 100%;
  font-size: 1.0em;
  text-align: center;
`;

export const AnyTextarea = styled.textarea`
  width: 100%;
  font-size: 1em;
  height: 4em;
`;

export const AnySelect = styled.select`
  width: 100%;
  text-align: center;
  font-size: 1em;
`;

export const AnyButton = styled.button`
  cursor: pointer;
  background-color: #d7e4f6;
  border: 0.05em solid #292929;
  border-radius: 3px;
  font-size: 1em;
  //font-weight: bold;
  font-style: italic;
  width: 30%;
`;

export const CheckBox = (props) => {
    return <div style={{"display": "flex", "paddingTop": "0.5em"}}>
        <input type="checkbox" checked={props.value} onChange={() => {
            props.setValue(!props.value)
        }}/>
        <CaptionText>{props.caption}</CaptionText>
    </div>
}
