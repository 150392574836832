import React, {useState} from "react";

import {Header, StatusText} from "../common/layout.js";
import DateInput from "./DateInput.js";
import CategorySelect from "./CategorySelect.js";
import AmountInput from "./AmountInput.js";
import AdditionalInformationInput from "./AdditionalInformationInput.js";
import {AButton} from "../common/baseInputs.js";
import {subcategoryCountForCategory} from "../data/categories/categoriesProvider.js";
import {useNavigate} from "react-router";
import {standardizeDate} from "../common/utils.js";

const NewTransactionForm = ({showConfirmation, setSaveTotal}) => {

    const [date, setDate] = useState(new Date());
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [selectedTag, setSelectedTag] = useState('');
    const [amount, setAmount] = useState('');
    const [isIncome, setIsIncome] = useState(false);
    const [isReimbursable, setIsReimbursable] = useState(false);
    const [memo, setMemo] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [requestConfirmation, setRequestConfirmation] = useState(false);

    const navigate = useNavigate();

    const validate = () => {
        if (!selectedCategory) {
            return "Choose a category";
        } else if (subcategoryCountForCategory(selectedCategory) > 0 && !selectedSubcategory) {
            return "Choose a subcategory";
        } else if (amount <= 0) {
            return "Amount must be > 0";
        } else if (!memo) {
            return "Write a memo";
        } else {
            return "";
        }
    }

    const submitForm = async () => {
        let transformedDate = standardizeDate(date).join('-');

        let body = JSON.stringify({
            date: transformedDate,
            category: selectedCategory,
            subcategory: selectedSubcategory,
            tag: selectedTag,
            amount: amount,
            isIncome: isIncome,
            isReimbursable: isReimbursable,
            memo: memo
        });

        let result = await fetch('/transaction', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: body
        })

        if (result.status === 401) {
            navigate('/');
        } else if (result.status !== 200) {
            setSaveTotal([-1, -1]);
        }

        let resultData = await result.json();
        setSaveTotal([resultData.expenses, resultData.income]);
    }

    const handleSubmit = () => {
        let errorMessage = validate();
        let submittable = !errorMessage;
        setErrorMessage(errorMessage);

        if (errorMessage) {
            setRequestConfirmation(false);
            return;
        }

        if (submittable && requestConfirmation) {
            submitForm().then(showConfirmation());
        } else {
            setRequestConfirmation(submittable);
        }
    }

    return (
        <>
            <Header>New Transaction</Header>
            <div style={{"display":"flex", "alignContent":"flexStart", "width":"100%"}}>
                <AButton width='30%' onClick={() => navigate('/admin')}><div>Admin</div></AButton>
            </div>
            <DateInput name="Date"
                       dateStore={[date, setDate]}/>
            <CategorySelect name="Category"
                            categoryStore={[selectedCategory, setSelectedCategory]}
                            subcategoryStore={[selectedSubcategory, setSelectedSubcategory]}
                            tagStore={[selectedTag, setSelectedTag]}/>
            <AmountInput name="Amount"
                         amountStore={[amount, setAmount]}
                         isIncomeStore={[isIncome, setIsIncome]}
                         isReimbursableStore={[isReimbursable, setIsReimbursable]}/>
            <AdditionalInformationInput name="Memo"
                                        memoStore={[memo, setMemo]}/>

            <StatusText condition={errorMessage} color={"red"}>{errorMessage}</StatusText>
            <StatusText condition={requestConfirmation} color={"green"}>Please Confirm...</StatusText>
            <AButton onClick={handleSubmit} width='100%'>Submit</AButton>
        </>
    )
}

export default NewTransactionForm;