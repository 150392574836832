import React, { useEffect } from "react";
import { useNavigate } from "react-router";

export const Redirect = ({ path }) => {
    let navigate = useNavigate();
    useEffect(() => navigate(path), []);
    return null;
}

export const authenticate = async (callback) => {
    let res = await fetch('/isAuthorized', {
        method: "HEAD",
        headers: {
            "Content-Type": "application/json",
        }
    });

    callback(res);
}

export const standardizeDate = (date) => {
    const dateString = date.toLocaleDateString('en-US', {
        "year": "numeric",
        "month": "2-digit",
        "day": "2-digit"
    })
    const [month, day, year] = dateString.split('/');
    return [year, month, day];
}

export const transactionDateComparator = (a, b) => {
    if (a.date > b.date) {
        return 1;
    } else if (a.date < b.date) {
        return -1;
    } else {
        return 0;
    }
}

export const transactionAmountComparator = (a, b) => {
    let aAmount = parseFloat(a.amount);
    let bAmount = parseFloat(b.amount);
    if (aAmount < bAmount) {
        return 1;
    } else if (aAmount > bAmount) {
        return -1;
    } else {
        return 0;
    }
}

export const transactionComparator = (type) => {
    switch (type) {
        case "amount":
            return transactionAmountComparator;
        case "date":
            return transactionDateComparator
    }
}

export const getSpentColor = (total) => {
    if (total > 1000) {
        return 'red';
    } else if (total > 500) {
        return 'orange';
    } else {
        return 'green';
    }
}