import React from "react";
import {InputTextArea} from "../common/baseInputs.js";
import {SectionHeader} from "../common/layout.js";

const AdditionalInformationInput = ({name, memoStore}) => {

    const [memo, setMemo] = memoStore;

    return (
        <>
            <SectionHeader>{name}</SectionHeader>
            <InputTextArea placeholder={"Memo..."} value={memo} onChange={setMemo}/>
        </>
    )
}

export default AdditionalInformationInput;