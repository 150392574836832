import React, {useEffect, useState} from "react";

import {BaseColor, BigHeader, BigStatusText, CenterContainer, Header, StatusText, VertBox} from "../common/layout.js";
import {AButton, InputText} from "../common/baseInputs.js";
import {useNavigate} from "react-router";
import {authenticate} from "../common/utils.js";

const Portal = () => {

    const [accessKey, setAccessKey] = useState('');
    const [fail, setFail] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        authenticate(res => {
            if (res.status === 200) {
                navigate('/submit')
            }
        });
    }, []);

    const login = async () => {
        let res = await fetch("/authorize", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                code: accessKey
            })
        });
        let code = res.status;
        if (code === 200) {
            navigate('/submit')
        } else {
            setFail(true);
        }
    }

    return (
        <>
            <CenterContainer>
                <VertBox>
                    <BigHeader>PÆY</BigHeader>
                    <BigStatusText condition={true} color={BaseColor}>Enter Access Key</BigStatusText>
                    <br/>
                    <InputText autocomplete
                               type="password"
                               placeholder="Enter Access Key..."
                               value={accessKey}
                               onChange={setAccessKey}/>
                    <br/>
                    {fail && <><BigStatusText condition={fail} color={'red'}>Nope!</BigStatusText><br/></> }
                    <AButton onClick={login} width='60%'>Submit</AButton>
                </VertBox>
            </CenterContainer>
        </>
    )
}

export default Portal;